import * as React from 'react'
import { ReceptionBookingDetailsPrices } from '@models/reception'
import { asDecimal, formatPercentage, formatPrice } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'
import { Progress } from 'reactstrap'
import { ReservationMinimumDepositLabel } from '@modules/reservations/details/deposit/labels/reservation-minimum-deposit-label'

const MINIMUM_DEPOSIT_AMOUNT = 150

interface Props {
  prices: ReceptionBookingDetailsPrices
  isDepositReturned: boolean
  isBookingActive: boolean
}

export const ReservationDepositRestToSpend = ({ prices, isDepositReturned, isBookingActive }: Props): JSX.Element => {
  const restToSpend = asDecimal(prices.deposit_amount).minus(prices.deposit_charge).toString()
  const restToSpendPercentage = asDecimal(100)
    .minus(asDecimal(prices.deposit_charge).mul(100).div(prices.deposit_amount))
    .toString()

  const minimumDepositExceeded = asDecimal(restToSpend).lt(MINIMUM_DEPOSIT_AMOUNT)

  const getProgressColor = () => {
    if (minimumDepositExceeded) return 'danger'
    return 'primary'
  }

  return (
    <>
      {minimumDepositExceeded && (
        <ReservationMinimumDepositLabel spendLimit={asDecimal(restToSpend).lte(0) ? 'used' : 'almost-used'} />
      )}

      <div className="mb-1 d-flex">
        <span>
          {isBookingActive ? 'Pozostało do wykorzystania ' : 'Wykorzystano '}
          <strong>{formatPrice(restToSpend)} </strong> z {formatPrice(prices.deposit_amount)} (
          {formatPercentage(restToSpendPercentage)})
        </span>

        {isDepositReturned && (
          <div className="text-green ml-auto">
            <IconWithText
              icon="uil-check font-12"
              text="kaucja zwrócona"
              textClass="font-11"
              wrapperClassNames="mr-1"
            />
            <strong className="font-15">{formatPrice(150)}</strong>
          </div>
        )}
      </div>
      <Progress value={restToSpendPercentage} color={getProgressColor()} className="mb-2" style={{ height: 10 }} />
    </>
  )
}
