import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import ReceptionBookingCheckBillDetailsPayments from '@modules/reception/common/reception-booking-check-bill-details/step-bill-details-payments'
import { ReceptionBookingDetails } from '@models/reception'

interface ReceptionBookingCheckInDepositDetailsProps {
  booking: ReceptionBookingDetails
}

export const ReceptionBookingCheckInDepositDetails: React.FC<ReceptionBookingCheckInDepositDetailsProps> = ({
  booking,
}) => (
  <>
    <div className="d-flex mb-2 justify-content-between align-content-end">
      <strong className="font-14 text-strong">2. Szczegóły kaucji:</strong>
    </div>
    <table className="reception__booking-check-out__bill-step__table">
      <tbody>
        <tr className="reception__booking-check-out__bill-step__table__row">
          <td className="reception__booking-check-out__bill-step__table__column text-grey text-semi-strong">
            Wymagana kaucja:
          </td>
          <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs">
            {formatPriceShort(booking.deposit_amount)}
          </td>
          <td width={25} className="reception__booking-check-out__bill-step__table__column" />
          <td width={25} className="reception__booking-check-out__bill-step__table__column" />
        </tr>
        <ReceptionBookingCheckBillDetailsPayments
          readOnly={false}
          label="Wpłacona kaucja"
          isDeposit={true}
          rowClassName="bg-platinum"
          defaultIsOpen={false}
        />
        <tr className="reception__booking-check-out__bill-step__table__row reception__booking-check-out__bill-step__table__row--is-last bg-platinum">
          <td className="reception__booking-check-out__bill-step__table__column text-grey text-strong font-14">
            Kaucja do zapłaty
          </td>
          <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs text-strong font-18 py-2">
            {formatPriceShort(booking.required_deposit_amount)}
          </td>
          <td width={25} className="reception__booking-check-out__bill-step__table__column" />
          <td width={25} className="reception__booking-check-out__bill-step__table__column" />
        </tr>
      </tbody>
    </table>
  </>
)
