import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { ReservationDepositProgress } from '@modules/reservations/details/deposit/reservation-deposit-progress'
import classNames from 'classnames'
import { asDecimal } from '@helpers/utils'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationDeposit = React.forwardRef(
  ({ booking }: Props, ref: React.RefObject<HTMLDivElement>): React.ReactNode => {
    const depositPaid = asDecimal(booking.prices.deposit_payments_total_amount).gte(booking.prices.deposit_amount)

    return (
      <Card className={classNames({ 'border border-danger': !depositPaid })} innerRef={ref} id="deposit">
        <CardBody className="d-flex">
          <div className="col-4">
            <IconWithText
              flexClassName="d-block"
              icon="uil-money-withdraw font-20 lh-0"
              text="Kaucja"
              textClass="font-18 fw-semi-bold"
              wrapperClassNames="text-primary"
            />
            <IconWithText
              icon="uil-angle-right font-18"
              text={`Wpłaty (${0})`}
              wrapperClassNames="flex-row-reverse mt-1"
            />
          </div>
          <div className="col-8">
            <ReservationDepositProgress booking={booking} isDepositFullyPaid={depositPaid} />
          </div>
        </CardBody>
      </Card>
    )
  },
)
