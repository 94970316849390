import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { ReceptionBookingCheckBillDetails } from '@modules/reception/common/reception-booking-check-bill-details'
import { ReceptionBookingCheckPaymentsBoxCheckout } from '@modules/reception/common/reception-booking-check-payments-box-checkout/step-payments-box-checkout'
import { ReceptionBookingCheckInDepositDetails } from '@modules/reception/checkin/step-payments/reception-booking-check-in-deposit-details'
import { ReceptionBookingCheckInStepPaymentsBoxDeposit } from '@modules/reception/checkin/step-payments/step-payments-box-deposit'
import { ReceptionBookingDetails } from '@models/reception'
import { asDecimal } from '@helpers/utils'
import { calculateRestToPayCheckin } from '@modules/reception/checkout/step-settlement/calculate-rest-to-pay'
import { isReservedByBooking } from '@modules/reception/checkin/preview/reception-booking-check-in-preview-bill'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReceptionBookingCheckInStepPaymentsContent = ({ booking }: Props): JSX.Element => {
  const restToPay = isReservedByBooking(booking)
    ? asDecimal(booking.required_climatic_tax)
    : calculateRestToPayCheckin(booking)

  return (
    <>
      <div className="border p-3 bg-white-smoke">
        <Row>
          <Col md={6}>
            <ReceptionBookingCheckBillDetails
              title="1. Rachunek za pobyt:"
              booking={booking}
              isOpen={false}
              restToPay={restToPay}
            />
          </Col>
          <Col md={6}>
            <ReceptionBookingCheckPaymentsBoxCheckout booking={booking} restToPay={restToPay}>
              {isReservedByBooking(booking) && (
                <p className="mt-2 font-12 text-danger">
                  Oczekuje na wpłatę zaliczki za pobyt. Pobierz opłatę klimatyczną.
                </p>
              )}
            </ReceptionBookingCheckPaymentsBoxCheckout>
          </Col>
        </Row>
      </div>
      <div className="border my-3 p-3 bg-white-smoke">
        <Row>
          <Col md={6}>
            <ReceptionBookingCheckInDepositDetails booking={booking} />
          </Col>
          <Col md={6}>
            <ReceptionBookingCheckInStepPaymentsBoxDeposit booking={booking} />
          </Col>
        </Row>
      </div>
    </>
  )
}
